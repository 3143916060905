import '../App.js'
import './aboutus.css'
import GoogleMaps from '../components/googlemaps.js'


function AboutUs() {

  return (<>
    <div className='aboutProfile container-md text-start mt-5 mb-5'>
      <div className='row justify-content-center mb-4'>
        <div className='col-12 col-md-4 text-center mb-3 mb-md-0'>
          <img src='/images/TradeitLogo(Title)600.jpg' className='aboutLogo img-fluid' alt="TradeitLogo" />
        </div>
        <div className='col-12 col-md-6'>
          <div className='profileInfo'>
            <h2 className='text-center text-md-start mb-3'>Corporate Information</h2>
            <p className="text-center text-md-start fw-normal custom-sm-fs custom-md-fs" style={{color:"#666666"}}>
              Tradeit Pte Ltd is a Singapore incorporated company in the year 2019 with the objective of becoming a valued supplier
              in electronics materials supply, robotics automation, and recycling service provider. Supply of Electronic Materials
              for the Chemical, Biomedical, Semiconductor, and Electronics Manufacturing Industries, Worldwide.
            </p>
          </div>
        </div>
      </div>
      <div className="explore-section text-center mt-5">
      <h3 className="mb-4">Explore Our Services & Products</h3>
      <div className="d-flex justify-content-center">
        <a href="/service" className="btn btn-outline-primary me-3">See Our Services</a>
        <a href="/product" className="btn btn-outline-primary">Explore Our Product Range</a>
      </div>
    </div>
      <div className="location-section mt-5">
        <h3 className="text-center mb-3">Our Locations</h3>
        <div className='row'>
          <div className='col-12 col-md-6 mb-4'>
            <h4 className="text-center mb-2">Head Office (Singapore)</h4>
            <GoogleMaps 
              gMapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7608668692305!2d103.88995622447209!3d1.3191716116747334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da181860f6a30b%3A0xda2696a584b95326!2sSingapore%20409051!5e0!3m2!1sen!2ssg!4v1724969118208!5m2!1sen!2ssg"
              locationDetails="60 Paya Lebar Road #06-46 <br/> Paya Lebar Square, S(409051)"
            />
          </div>
          <div className='col-12 col-md-6 mb-4'>
            <h4 className="text-center mb-2">Sales Office (Thailand)</h4>
            <GoogleMaps 
              gMapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241803.9528351472!2d98.71895921590729!3d18.745193013466764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3700ec5f0dc1%3A0x1c0346c928fabaf0!2sChiang%20Mai%2050230%2C%20Thailand!5e0!3m2!1sen!2ssg!4v1724970116142!5m2!1sen!2ssg"
              locationDetails="99 M.3 Harn Kaew, Hang Dong, Chiang Mai 50230, Thailand"
            />
          </div>
        </div>
      </div>
    </div>

    </>
  )
}

export default AboutUs